<template>
  <ValidationObserver
    ref="observer2"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      v-if="$can('create', 'catalog')"
      class="p-0"
      method="POST"
      novalidate
      autocomplete="off"
      @submit.prevent="handleSubmit(saveSize)"
    >
      <b-select-validation
        v-model="newSize.type"
        :label="$t('type')"
        class="mb-2"
        vid="type"
        rules="required"
        :expanded="true"
        :disabled="!!newSize.id || disableType"
      >
        <template>
          <option
            v-for="opt in types"
            :key="opt.id"
            :value="opt.id"
          >
            {{
              opt.label
            }}
          </option>
        </template>
      </b-select-validation>
      <div class="columns is-mobile is-multiline is-variable">
        <div class="column is-6">
          <b-input-validation
            v-model="newSize.value"
            :placeholder="placeholders.number['2']"
            rules="required"
            vid="value"
            :label="$tc('value', 1)"
          />
        </div>
        <div class="column is-6">
          <b-input-validation
            v-model="newSize.rank"
            :placeholder="placeholders.number['1']"
            vid="rank"
            :label="$t('rank') + ' (' + $t('optional') + ')'"
          />
        </div>
      </div>

      <div class="mt-2">
        <b-button
          type="is-primary"
          :disabled="save_size_loading"
          :loading="save_size_loading"
          class="mr-3"
          tag="input"
          native-type="submit"
          :value="$t('save')"
        >
          {{ $t("save") }}
        </b-button>
        <b-button
          :disabled="save_size_loading"
          @click="$emit('cancel')"
        >
          {{ $t("cancel") }}
        </b-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    editObj: {
      type: Object,
      default: () => {}
    },
    types: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      default: () => []
    },
    disableType: {
      type: Boolean,
      default: false
    },
    forceAdd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      save_size_loading: false,
      newSize: {
        type: null,
        value: null,
        rank: null
      }
    };
  },
  watch: {
    editObj(newVal) {
      if (newVal.id) {
        this.newSize = newVal;
      } else {
        this.clearInputs();
      }
    }
  },
  created() {
    this.newSize.type = this.editObj.type;
    if (this.editObj.typedValue) this.newSize.value = this.editObj.typedValue;
  },
  methods: {
    clearInputs() {
      this.newSize = {
        type: null,
        value: null,
        rank: null
      };
      this.$refs.observer2.reset();
    },
    saveSize() {
      this.save_size_loading = true;
      let item = this.newSize;

      let url = `catalog/sizes${item.id ? "/" + item.id : ""}`,
        method = item.id ? "PUT" : "POST",
        data = {
          rank: item.rank
        };

      if (item.id) {
        data.size = item.value;
      } else {
        data.size = item.value;
        data.size_type_id = item.type;
      }

      this.$axios({
        method: method,
        url: url,
        data: data
      })
        .then(({ data }) => {
          if (item.id) {
            data.id = item.id;
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else {
            data.type_id = item.type;
            this.$root.notify(this.$t("successfully_saved"), "is-success");
          }
          this.$emit("created", data);
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.save_size_loading = false));
    }
  }
};
</script>

