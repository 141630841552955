<template>
  <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
    <div
      class="column is-6-tablet is-12-mobile p-0 py-2 column-separator-right"
    >
      <b-select-validation
        v-model="selectedType"
        style="max-width: 200px;"
        class="mx-2"
        size="is-small"
        :expanded="true"
        :label="$t('type')"
        @input="filterType"
      >
        <template>
          <option
            v-for="opt in types"
            :key="opt.id"
            :value="opt.id"
          >
            {{
              opt.label
            }}
          </option>
        </template>
      </b-select-validation>
      <b-table
        :data="FilteredItems"
        paginated
        :loading="items_loading"
        pagination-position="top"
        :per-page="perPage"
        :current-page="currentPage"
        hoverable
        mobile-cards
        :total="FilteredItems.length"
        :row-class="
          (row, index) =>
            `attribute_${row.id}${
              row.id == updatedAttribute ? ' bg-updated' : ''
            }`
        "
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            v-slot="props"
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :numeric="head.numeric"
            :searchable="head.searchable"
            v-bind="head"
          >
            <template v-if="head.value == 'actions'">
              <b-dropdown
                v-if="
                  props.row.count == 0 || $can('update', 'catalog.override')
                "
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  aria-role="listitem"
                  @click="editAttribute(props.row)"
                >
                  <b-icon
                    icon="eye"
                    size="is-small"
                  />
                  {{ $t("view") }}
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="confirmDeleting(props.row, props.index)"
                >
                  <b-icon
                    icon="delete"
                    type="is-danger"
                    size="is-small"
                  />
                  {{ $t("delete") }}
                </b-dropdown-item>
              </b-dropdown>
              <template v-else>
                -
              </template>
            </template>
            <template v-else-if="head.value == 'value'">
              {{ props.row.value.formated || props.row.value.value || "-" }}
            </template>
            <a
              v-else-if="head.value == 'id'"
              href="javascript:;"
              @click="editAttribute(props.row)"
            >
              {{ props.row.id }}
            </a>
            <template v-else>
              {{ props.row[head.value] || "-" }}
            </template>
          </b-table-column>
        </template>
        <EmptyBlock
          slot="empty"
          icon="variable"
        />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="FilteredItems.length"
        :current-page="currentPage"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (currentPage = val)"
      />
    </div>

    <div
      id="form"
      class="column is-6-tablet is-12-mobile pt-3"
    >
      <div class="sticky">
        <p class="is-size-5 pb-5">
          <b>{{ $t("create_attribute") }}</b>
        </p>
        <AttributeForm
          :edit-obj="editObj"
          :types="types"
          :languages="languages"
          @created="addOrUpdateItem"
          @cancel="cancelEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AttributeForm from "@/components/forms/Attributes/Attributes.vue";

export default {
  components: {
    AttributeForm
  },
  props: {
    search: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      items_loading: false,
      save_attribute_loading: false,
      updatedAttribute: null,
      currentPage: 1,
      perPage: 50,
      perPages: [50, 100, 200, 500],
      selectedType: null,
      headers: [
        {
          label: this.$i18n.t("id"),
          value: "id"
        },
        {
          label: this.$i18n.tc("value", 1),
          value: "value",
          width: 200
        },
        {
          label: this.$i18n.tc("sku", 2),
          value: "count",
          centered: true,
          numeric: true,
          width: 100
        },
        {
          label: this.$t("actions"),
          value: "actions",
          centered: true,
          numeric: true,
          width: 80
        }
      ],
      items: [],
      types: [],
      languages: [],
      editObj: {
        type: null,
        value: null,
        trans: {}
      }
    };
  },
  computed: {
    FilteredItems() {
      return this.items.filter(option => {
        return (
          JSON.stringify(option)
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != -1
        );
      });
    }
  },
  created() {
    if (this.$can("read", "catalog")) {
      this.getItems();
    }
  },
  methods: {
    addOrUpdateItem(data) {
      let index = this.items.findIndex(a => a.id == data.id);

      if (index != -1) {
        this.items.splice(index, 1, data);
      } else if (this.selectedType == data.type_id) {
        this.items.unshift(data);
      }
      this.updatedAttribute = data.id;
      this.scrollToMobile(`.attribute_${data.id}`);

      setTimeout(() => {
        this.updatedAttribute = null;
      }, 2000);
      this.cancelEdit();
    },
    hasTrans(id) {
      if (!id) return false;
      let type = this.types.find(t => t.id == id);
      return type && type.trans;
    },
    confirmDeleting(item) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteAttribbute(item)
      });
    },
    deleteAttribbute(item) {
      this.$axios
        .delete("catalog/attributes/" + item.id)
        .then(() => {
          let index = this.items.findIndex(a => a.id == item.id);
          this.items.splice(index, 1);
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch(e => this.$root.clientError(e));
    },
    cancelEdit() {
      this.editObj = {};
    },
    getItems() {
      this.items_loading = true;
      let params = this.selectedType ? `?type_id=${this.selectedType}` : "";
      this.$axios
        .get("catalog/attributes" + params)
        .then(res => {
          this.items = res.data.list;
          this.languages = res.data.filters.languages;
          this.types = res.data.filters.types;
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.items_loading = false));
    },
    editAttribute(item) {
      this.editObj = {
        id: item.id,
        type: this.selectedType,
        value: item.value.value,
        trans: item.trans
      };
      this.scrollToMobile("#form");
    },
    filterType() {
      this.getItems();
    },
    scrollToMobile(el) {
      if (this.$device.mobile) {
        this.$scrollTo(el);
      }
    }
  }
};
</script>
