<template>
  <Page has-actions>
    <template
      v-if="$can('read', 'catalog')"
      slot="actions"
    >
      <div slot="actions">
        <div class="field has-addons">
          <div class="is-flex">
            <b-button
              v-if="$device.mobile"
              size="is-small"
              rounded
              @click="showMobile()"
            >
              <b-icon
                icon="magnify"
                size="is-small"
              />
            </b-button>
            <b-input
              ref="search"
              v-model="search"
              :size="$device.mobile ? 'is-small' : ''"
              :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
              :placeholder="$t('search')"
              icon="magnify"
              rounded
              @blur="hideMobile()"
            />
          </div>
        </div>
      </div>
    </template>
    <Card content-class="px-0 py-1">
      <div
        v-if="loading"
        style="min-height: 200px"
      >
        <b-loading
          v-model="loading"
          :is-full-page="false"
        />
      </div>
      <b-tabs
        v-else
        v-model="selected"
        expanded
        class="paddingless"
      >
        <b-tab-item
          :label="$tc('attribute', 2)"
          value="1"
        >
          <Attributes
            v-if="selected == '1'"
            :search="search"
          />
        </b-tab-item>
        <b-tab-item
          :label="$tc('size', 2)"
          value="2"
        >
          <Sizes
            v-if="selected == '2'"
            :search="search"
          />
        </b-tab-item>
      </b-tabs>
    </Card>
  </Page>
</template>

<script>
import Attributes from "@/components/modules/Attributes/Attributes";
import Sizes from "@/components/modules/Attributes/Sizes";
export default {
  components: {
    Attributes,
    Sizes
  },
  data() {
    return {
      loading: false,
      search: "",
      selected: "1",
      searchFocused: false
    };
  },
  watch: {
    selected() {
      this.search = "";
    }
  },
  created() {
    this.updateTitle(this.$tc("attribute", 2));
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    }
  }
};
</script>
